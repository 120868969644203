import Vue from "vue";
import App from "./App.vue";
// import ElementUI from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css'
import router from "./router";
import store from "./store";
import i18n from "./lang/index";
import "amfe-flexible";
// import '@/style/index.scss'
// Vue.use(ElementUI)
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles

AOS.init();
Vue.use(router);
Vue.use(store);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
